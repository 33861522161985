<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      statusCode: null,
      processing: false,
      standards: [],
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getStandards(programSelections) {
      var programIDs = programSelections.map((p) => `programIDs=${p.programID}`).join("&");
      await axios
        .get(
          // We don't need to add an extra & symbol because each program ID ends with one
          `${WebAPIUrl}/api/navigator-standard?${programIDs}`
        )
        .then((result) => {
          this.standards = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async downloadStandards(programID, services) {
      this.processing = true;
      var stringifiedServices = JSON.stringify(services);
      await axios
        .get(
          `${WebAPIUrl}/api/navigator-standard/download-standards/${programID}/${stringifiedServices}`,
          { responseType: "blob" }
        )
        .then((response) => {
          //content disposition contains our file name along with type and content type.  need to retrieve  just the filename
          var contentDisposition = response.headers["content-disposition"];
          var fileNameRaw = contentDisposition.split(";")[1].trim();
          var fileName = fileNameRaw.substring(9, fileNameRaw.length);
          //create blob object, append to html and then click it
          // https://stackoverflow.com/questions/53066089/how-to-open-pdf-blob-using-browsers-pdf-viewer-rather-than-downloading
          const blob = new Blob([response.data], { type: "application/pdf" });
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = fileName;
          a.style.position = "fixed";
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.processing = false;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
