<template>
  <full-page-overlay :processing="processing">
    <section>
      <h1 class="bold-header-text app-header">Standards</h1>
      <div class="standards-info-text">
        <p>
          Select the programs, services, and distinctions applicable to your company and click
          Preview, Purchase, or Download. To receive complimentary standards please reach out to
          your ACHC Representative listed on the Dashboard.
        </p>
        <b-card class="dashboard-card">
          <p>
            <b>ACHC Accreditation</b>
            is validation, based on rigorous survey review, that a healthcare organization’s
            policies, processes, and care delivery meet our recognized standards for quality and
            safety.
          </p>
          <p>
            <b>ACHC Certification</b>
            results from a focused review and evaluation of a program within a healthcare
            organization that confirms compliance with standards for specialty care. ACHC
            Accreditation is not a prerequisite for ACHC Certification.
          </p>
          <p>
            An <b>ACHC</b> award of <b>Distinction</b> recognizes excellence in a specialty service
            delivered by an ACHC-accredited healthcare organization.
          </p>
          <p>
            Distinctions require meeting additional standard requirements and incurring added costs.
            Please reach out to your dedicated account representative for more information.
          </p>
          <p>
            <b>ACHC</b> Standards are categorized under <b>Services</b>, to maintain relevance
            within the category of care provided by an organization.
          </p>
          <p>
            Applying for reaccreditation? Be sure to also download the latest survey preparation
            documents in the
            <a href="#" @click="goToResources">Resources</a> section.
          </p>
        </b-card>
      </div>
      <div class="resource-options mt-3" ref="commands">
        <b-form-group label="Selected Programs">
          <multiselect
            v-model="programSelections"
            :options="groupedPrograms"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('dropdown.select-a-program')"
            label="programDescription"
            track-by="programID"
            group-values="options"
            group-label="label"
            :preselect-first="false"
            @input="submitSelections"
          >
            <template slot="option" slot-scope="props">
              <span
                v-if="props.option.$isLabel"
                class="option__title"
                :title="props.option.$groupLabel"
                >{{ props.option.$groupLabel }}</span
              >
              <span v-else class="option__title" :title="props.option.programDetailDescription">{{
                props.option.programDescription
              }}</span>
            </template>
          </multiselect>
        </b-form-group>
      </div>
      <achc-collapsible-card
        v-for="(programGroup, index) in standards"
        :key="`${programGroup.programDescription.replace(/\s/g, '')}-${index}`"
        :card-visible="programGroup.visible"
      >
        <template slot="header">
          <div class="standard-card-header">
            <h2 class="standard-program-header">
              {{
                programGroup.programDescription +
                " (" +
                programGroup.programInitials +
                ") " +
                programGroup.accreditationOrCertification
              }}
            </h2>
          </div>
        </template>
        <template slot="body">
          <div class="action-button">
            <div class="program-group-type">
              <option-group
                class="standard-option-group"
                :options="options"
                v-model="programGroup.type"
              >
              </option-group>
            </div>
            <div class="standard-download-btn" v-if="companyObject.canDownloadStandards">
              <b-button
                @click="submitStandardSelections(programGroup.programID)"
                ref="downloadBtn"
                class="standards-submit-btn btn-blue"
                :key="programGroup.programID"
                :disabled="isDownloadButtonDisabled(programGroup.programID)"
              >
                Download
              </b-button>
            </div>
            <div id="pur-prev-sec" v-else>
              <b-button
                size="sm"
                id="purchaseBtn"
                class="standards-submit-btn btn-blue"
                :key="`purchase_${programGroup.programID}`"
                @click="addStandardsPurchaseToCart()"
              >
                Purchase
              </b-button>
            </div>
          </div>
          <h3 class="standard-program-header">{{ programGroup.programDetailDescription }}</h3>
          <div
            v-for="serviceGroup in programGroup.serviceGroups"
            :key="`${serviceGroup.group.replace(/\s/g, '')}-
          ${programGroup.programDescription.replace(/\s/g, '')}`"
            class="row"
          >
            <h3 class="standard-group-header">
              {{ serviceGroup.group }}
            </h3>
            <div
              v-for="service in serviceGroup.services"
              :key="`${service.serviceInitials.replace(
                /\s/g,
                ''
              )}-${programGroup.programDescription.replace(/\s/g, '')}`"
              class="col-xs-12 col-md-6"
            >
              <label>
                <input v-model="service.selected" class="standard-checkbox" type="checkbox" />
                <div class="standard-info-group">
                  <h3 class="standard-program-header">
                    {{ service.serviceDescription + " (" + service.serviceInitials + ")" }}
                  </h3>
                  <p class="bold-gray-text mb-4" v-html="service.serviceDetailDescription"></p>
                </div>
              </label>
            </div>
          </div>
        </template>
      </achc-collapsible-card>
      <div v-if="emptyStateVisible" class="resources-empty-state">
        No standards available based on current selections
      </div>
    </section>
  </full-page-overlay>
</template>
<script>
import OptionGroup from "../../components/Common/OptionGroup.vue";
import CollapsibleCard from "../../components/Common/CollapsibleCard.vue";
import Multiselect from "vue-multiselect";
import router from "../../router";
import { bus } from "../../main";

import CompanyMixin from "../../mixins/CompanyMixin.vue";
import ProgramMixin from "../../mixins/ProgramMixin.vue";
import StandardMixin from "../../mixins/StandardMixin.vue";
import DirtyDataMixin from "../../mixins/DirtyDataMixin.vue";
import ShoppingCartMixin from "../../mixins/ShoppingCartMixin.vue";

export default {
  props: ["companyID"],
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    this.company = JSON.parse(localStorage.getItem("currentCompany"));
    await this.getCompanyObjectByID(this.company.companyID);
    await this.getSelectedProgramsByCompany(this.company.companyID);
    this.programSelections = this.selectedProgramsByCompany;

    await this.getAllProgramsByType();
    bus.$on("dirtyModalSaveChanges", (to) => {
      this.saveProgramsOfInterest(to);
    });
    this.initDirtyDataWatcher("programSelections");
    if (this.selectedProgramsByCompany.length > 0) {
      await this.submitSelections();
    }
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  destroyed() {
    bus.$off("dirtyModalSaveChanges");
  },
  data() {
    return {
      programSelections: [],
      options: [
        {
          text: "Standards",
          value: "Standards",
          tooltip: ["A PDF of the standards applicable to your program and services."],
        },
        // {
        //   text: "ACHC Process",
        //   value: "ACHC Process",
        //   tooltip: [
        //     "A PDF of ACHC policies and procedures related to the accreditation/certification process.",
        //   ],
        // },
        /*{
          text: "Survey Tools",
          value: "Survey Tools",
          tooltip: [
            "Resource tools to help you prepare your organization for a successful survey.",
          ],
        },*/
      ],
    };
  },
  mixins: [CompanyMixin, ProgramMixin, StandardMixin, DirtyDataMixin, ShoppingCartMixin],
  components: {
    "achc-collapsible-card": CollapsibleCard,
    Multiselect,
    "option-group": OptionGroup,
  },
  methods: {
    async submitSelections() {
      await this.getStandards(this.programSelections);
    },
    async submitStandardSelections(programID) {
      var servicesArray = this.getSelectedServicesByProgramID(programID).map(
        (x) => x.serviceInitials
      );
      await this.downloadStandards(programID, servicesArray);
    },
    goToResources() {
      router.push("resources");
    },
    async saveProgramsOfInterest(to) {
      this.company = JSON.parse(localStorage.getItem("currentCompany"));
      await this.updateSelectedPrograms(this.company.companyID, this.programSelections, null);
      this.setPreserveMessageAfterPush(true);
      this.$router.push(to.fullPath);
    },
    getSelectedServicesByProgramID(programID) {
      var checkedProgramServices = [];
      var serviceGroups = this.standards.find((g) => g.programID == programID).serviceGroups;
      for (var i = 0; i < serviceGroups.length; i++) {
        for (var j = 0; j < serviceGroups[i].services.length; j++) {
          var service = serviceGroups[i].services[j];
          if (service.selected) {
            checkedProgramServices.push(service);
          }
        }
      }
      return checkedProgramServices;
    },
    isDownloadButtonDisabled(programID) {
      var checkedProgramServices = this.getSelectedServicesByProgramID(programID);
      return checkedProgramServices.length == 0;
    },
    async addStandardsPurchaseToCart() {
      await this.addItemToCart(3);
      router.push("shopping-cart");
    },
  },
  computed: {
    emptyStateVisible() {
      return this.standards.length <= 0 && this.programSelections.length > 0;
    },
  },
};
</script>
<style scoped>
#pur-prev-sec {
  display: grid;
  justify-content: space-between;
  align-content: space-between;
  justify-items: stretch;
  align-items: center;
}
#pur-prev-sec button#previewBtn {
  margin-top: 5px;
}
.action-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.action-button .program-group-type {
  margin-right: 20px !important;
}
</style>
