<template>
  <div class="option-group-container">
    <!-- Remove spaces from the option value so that it works as a key -->
    <div
      v-for="(option, index) in options"
      :key="`option-group-key-${option.value.replace(/\s/g, '')}`"
      :id="uniqueId(option.value)"
      class="option-group-option"
      :class="{
        'option-group-alternating-option': variant === 'alt',
        selected: selectedValues.includes(option.value),
      }"
      :style="{
        'border-left-color': getBorderLeftColor(option, index),
        'border-right-color': getBorderRightColor(option, index),
      }"
      @click="selectOption(option)"
    >
      <p class="option-group-text">{{ option.text }}</p>
      <b-tooltip
        v-if="option.tooltip !== undefined"
        :target="uniqueId(option.value)"
        triggers="hover"
      >
        <!-- If tooltip is an array of strings display it as a list -->
        <ul v-if="Array.isArray(option.tooltip)" class="option-group-tooltip-list">
          <li
            v-for="tooltipText in option.tooltip"
            :key="`tooltip-item-${tooltipText.replace(/\s/g, '')}`"
            class="option-group-tooltip-list-item"
          >
            {{ tooltipText }}
          </li>
        </ul>
        <!-- If tooltip is text then just render the text -->
        <p v-else class="option-group-tooltip-text">{{ option.tooltip }}</p>
      </b-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  // Variant includes solid and alt (alternates the color between primary and secondary)
  props: ["options", "values", "variant"],
  data() {
    return {
      selectedValues:
        this.values !== undefined
          ? this.values.filter((v) => v.selected).map((v) => v.value)
          : [this.options[0].value],
    };
  },
  methods: {
    selectOption(option) {
      if (this.selectedValues.includes(option.value)) {
        this.selectedValues = this.selectedValues.filter((v) => v !== option.value);
      } else {
        this.selectedValues.push(option.value);
      }

      this.$emit("input", this.selectedValues);
    },
    getBorderLeftColor(option, index) {
      if (index === 0 || this.selectedValues === undefined || this.selectedValues === null) {
        return "";
      }

      if (
        this.selectedValues.includes(this.options[index - 1].value) &&
        this.selectedValues.includes(option.value) &&
        this.variant !== "alt"
      ) {
        return "#ffffff";
      }

      return "";
    },
    getBorderRightColor(option, index) {
      if (
        index === this.options.length - 1 ||
        this.selectedValues === undefined ||
        this.selectedValues === null
      ) {
        return "";
      }

      if (
        this.selectedValues.includes(this.options[index + 1].value) &&
        this.selectedValues.includes(option.value) &&
        this.variant !== "alt"
      ) {
        return "#ffffff";
      }

      return "";
    },
    uniqueId(value) {
      return `option-group-${value.replace(/\s/g, "")}-${this._uid}`;
    },
  },
};
</script>
